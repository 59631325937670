var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer col-xl-11 col-xxl-10"},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('div',{staticClass:"col-6 d-flex justify-content-around"},[_vm._m(0),_c('ul',[_c('li',[_c('a',{staticClass:"footer__link",class:{ active: _vm.$route.path == '/' },attrs:{"href":"/"}},[_vm._v(" Главная ")])]),_vm._m(1),_vm._m(2)]),_c('ul',[_vm._m(3),_vm._m(4),_c('li',[_c('a',{staticClass:"footer__link",class:{ active: _vm.$route.path == '/examples' },attrs:{"href":"#examples"}},[_vm._v(" Проекты ")])])])]),_vm._m(5)]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"alt":"logo","width":"35","height":"82"}}),_c('span',{staticClass:"fs-6"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__link",attrs:{"href":"#about"}},[_vm._v(" О компании ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__link",attrs:{"href":"#services"}},[_vm._v(" Услуги ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__link",attrs:{"href":"#contact"}},[_vm._v(" Контакты ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"footer__link",attrs:{"href":"#news"}},[_vm._v(" Новости ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-auto"},[_c('div',{staticClass:"footer__info column"},[_c('div',{staticClass:"footer-group"},[_c('div',{staticClass:"footer-group__title"},[_vm._v(" Адрес ")]),_c('div',{staticClass:"footer-group__body"},[_vm._v(" г.Москва, ТиНАО, "),_c('br'),_vm._v(" г.Московский, ул.Солнечная, д.2А, стр.2, оф. 209 ")])]),_c('div',{staticClass:"footer-group__bottom"},[_c('div',{staticClass:"footer-group"},[_c('div',{staticClass:"footer-group__title"},[_vm._v(" Телефон ")]),_c('div',{staticClass:"footer-group__body"},[_vm._v(" +7 495 128 8025 ")])]),_c('div',{staticClass:"footer-group"},[_c('div',{staticClass:"footer-group__title"},[_vm._v(" E-mail ")]),_c('div',{staticClass:"footer-group__body"},[_vm._v(" energoprofstroy@mail.ru ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex d-lg-none row justify-content-between footer__mobile"},[_c('span',[_vm._v("«ПрофЭнергоСтрой» ©. \"ProfEnergoStroy\" ©. 2023")])])
}]

export { render, staticRenderFns }