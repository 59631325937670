/* eslint-disable */
<template>
  <div
    id="app"
  >
    <nav
      id="mainNav"
      class="navbar navbar-expand-lg fixed-top d-lg-block d-none"
    >
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          id="navbarNavAltMarkup"
          class="collapse navbar-collapse d-flex justify-content-center"
        >
          <div class="navbar-nav">
            <a
              href="/"
              class="nav-link ps-0"
              :class="{ active: block === 1 && $route.path != '/news' && $route.path != '/examples' }"
              @click="block = 1"
            >
              Главная
            </a>
            <a
              class="nav-link"
              :class="{ active: block === 2 && $route.path != '/news' && $route.path != '/examples' }"
              @click="goToRoute('#about')"
            >
              О компании
            </a>
            <a
              class="nav-link"
              :class="{ active: block === 3 && $route.path != '/news' && $route.path != '/examples' }"
              @click="goToRoute('#services')"
            >
              Услуги
            </a>
            <a
              class="navbar-brand"
              href="/"
            >
              <img
                src="./assets/logo.svg"
                alt="logo"
                width="35"
                height="82"
              >
              <span class="fs-6" /></a>
            <a
              class="nav-link"
              :class="{ active: block === 4 && $route.path == '/news' && $route.path == '/examples' }"
              @click="goToRoute('#contact')"
            >
              Контакты
            </a>
            <a
              class="nav-link"

              :class="{ active: $route.path == '/news' }"
              @click='$router.push({ name: "news"})'
            >
              Новости
            </a>
            <a
              class="nav-link"
              :class="{ active: $route.path == '/examples' }"
              @click='$router.push({ name: "examples"})'
            >
              Проекты
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div
      id="mobileNav"
      class="row fixed-top d-lg-none d-block"
    >
      <button
        class="open-menu"
        @click="showSideBar = true"
      >
        <span class="line-m line-m1" /><span class="line-m line-m2" /><span
          class="line-m line-m3"
        />
      </button>
    </div>

    <MobileNav
      :show-side-bar="showSideBar"
      @closeSidebar="sidebarToggle(false)"
    />

    <div class="app-wrap">
      <router-view
        :block='block'
      />
    </div>

    <hr class="divider col-xl-11 col-xxl-10">
    <!-- Footer-->
    <Footer />
  </div>
</template>

<script>
import Footer from './views/Footer.vue';
import MobileNav from './views/MobileNav.vue';

export default {
  components: {
    Footer,
    MobileNav,
  },
  data() {
    return {
      showSideBar: false,
      block: 1,
      positionY: 0,
    };
  },
  watch: {
    positionY() {
      if (window.scrollY >= 0 && window.scrollY < 750) {
        this.block = 1;
      } else if (window.scrollY > 750 && window.scrollY < 1720) {
        this.block = 2;
      } else if (window.scrollY > 1720 && window.scrollY < 2600) {
        this.block = 3;
      } else if (window.scrollY > 2600 && window.scrollY < 2983) {
        this.block = 4;
      }
    },
  },
  created() {
    window.addEventListener(
      'scroll',
      () => {
        this.positionY = window.scrollY;
      },
      false
    );
    this.positionY = parseInt(this.positionY, 10);
  },
  beforeCreate() {
    console.log('d')
  },
  mounted() {
    window.addEventListener('DOMContentLoaded', () => {
      // Navbar shrink function
      const navbarShrink = () => {
        const navbarCollapsible = document.body.querySelector('#mainNav');

        if (!navbarCollapsible) {
          return;
        }
        if (window.scrollY === 0) {
          navbarCollapsible.classList.remove('navbar-shrink');
        } else {
          navbarCollapsible.classList.add('navbar-shrink');
        }
      };
      // Shrink the navbar
      navbarShrink();
      // Shrink the navbar when page is scrolled
      document.addEventListener('scroll', navbarShrink);
    });
  },
  methods: {
    sidebarToggle(bool) {
      this.showSideBar = bool;
    },
    goToRoute(hashName) {
      switch (hashName) {
        case ('#about'):
          this.block = 1;
          break
        case ('#services'):
        this.block = 2;
        break
        case ('#contact'):
        this.block = 3;
        break
        default:
          this.block = 1
      }

      this.$emit('closeSidebar');
      this.$router.push({ name: 'index', hash: hashName });
    },
  },
  metaInfo() {
    return {
      title: 'Профэнергострой',
      link: [{ rel: 'favicon', href: '/src/assets/logo.svg' }],
    };
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  margin: 0 auto;

  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track-piece {
  background-color: #0b2e46;
}
::-webkit-scrollbar-thumb:vertical {
  height: 10px;
  background-color: #ec4c23;
  border-radius: 10px;
  background-clip: padding-box;
}
body {
  overflow-x: hidden;
  font-family: 'CeraPro_regular', sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh;
  background: content-box url("./assets/main-back.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

p {
  color: #fff;
  font-family: 'CeraPro_regular';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.app {
  &__title {
    color: #fff;
    font-size: 2.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__subtitle {
    color: #fff;
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
}
.app-wrap {
  margin: 0 50px;
  @media screen and (max-width: 768px) {
    margin: 0 25px;
  }
}
.main-content {
  margin-top: 56px;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.accordion-body {
  padding: 20px;
}
.accordion {
  --bs-accordion-btn-focus-border-color: #ec4c23 !important;
}

.open-menu {
  width: 31px;
  height: 29px;
  position: relative;
  float: left;
  margin: 50px 0 20px;
  border: none;
  background: 0 0;
}

.line-m {
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  background-color: #fff;
}
.line-m1 {
  top: 0;
}

.line-m2 {
  top: 12px;
  @media only screen and (max-width: 425px) {
    top: 10px;
  }
}
.line-m3 {
  top: 24px;
  @media only screen and (max-width: 425px) {
    top: 20px;
  }
}

.loading {
  height: 100vh;
  width: 100vh;
  color: #000;
}
</style>
