/* eslint-disable consistent-return */
import VueRouter from 'vue-router';

// Routes
import routeList from './routes';

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/MainPage.vue'),
  },
  ...routeList,
  {
    path: '*',
    redirect: '/',
    component: () => import('../views/MainPage.vue'),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
  const result = originalPush.call(this, location, onComplete, onAbort);
  if (result) {
    return result.catch((err) => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    });
  }
  return result;
};
const router = new VueRouter({
  base: '/',
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, _, next) =>
  // if (to.fullPath.includes('/#/')) {
  //   const path = to.fullPath.substr(2);
  //   return next(path);
  // }
   next()
);
export default router;
