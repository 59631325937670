import './scss/styles.scss';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import CoolLightBox from 'vue-cool-lightbox';
import MarqueeText from 'vue-marquee-text-component';
import VueMarqueeSlider from 'vue-marquee-slider';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

Vue.component('MarqueeText', MarqueeText);
Vue.config.productionTip = false;
Vue.use(VueMarqueeSlider);
Vue.use(VueMeta);
Vue.use(VueScrollTo);
Vue.use(CoolLightBox);
Vue.use(VueRouter);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
