export default [
  {
    path: '/news',
    name: 'news',
    meta: {
      pageTitle: 'Наши новости',
    },
    component: () => import('../views/NewsPage.vue'),
  },
  {
    path: '/examples',
    name: 'examples',
    meta: {
      pageTitle: 'Наши новости',
    },
    component: () => import('../views/ExamplesPage.vue'),
  },
];
