<template>
  <div>
    <div class="sidebar__outer" />
    <div
      class="d-flex flex-column text-white d-lg-none d-block sidebar"
      :class="{ 'sidebar-open': showSideBar }"
    >
      <button
        class="close-menu"
        @click="$emit('closeSidebar')"
      >
        <span class="line-c line-c1" /><span class="line-c line-c2" />
      </button>
      <hr>
      <ul class="nav flex-column mb-auto">
        <li class="nav-item">
          <a
            class="sidebar-link"
            href="/"
            @click="$emit('closeSidebar')"
          >
            Главная
          </a>
        </li>
        <li>
          <a
            class="sidebar-link"
            @click="goToRoute('about')"
          > О компании </a>
        </li>
        <li>
          <a
            class="sidebar-link"
            @click="goToRoute('services')"
          > Услуги </a>
        </li>
        <li>
          <a
            class="sidebar-link"
            @click="goToRoute('#contact')"
          > Контакты </a>
        </li>
        <li>
          <a
            class="sidebar-link"
            @click='goToPage("news")'
          >
            Новости
          </a>
        </li>

        <li>
          <a
            class="sidebar-link"
            @click='goToPage("examples")'
          >
            Проекты
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showSideBar: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goToPage(page) {
      this.$emit('closeSidebar')
      this.$router.push({ name: page })
    },
    goToRoute(hasht) {
      this.$emit('closeSidebar');
      this.$router.push({ name: 'index', hash: hasht });
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  background: rgba(8, 46, 71);
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  padding: 50px 50px 0;
  outline: 0;
  transform: translateX(-100%);
  transition: 0.5s;
  left: 0;
  right: auto;
  z-index: 1500;
  &__outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
  }
  &-open {
    display: flex;
    transform: translateX(0);
  }
}
.line-c {
  width: 100%;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 12px;
}
.line-c1 {
  transform: rotate(45deg);
  left: 0;
}
.line-c2 {
  transform: rotate(-45deg);
  right: 0;
}
.close-menu {
  width: 32px;
  height: 28px;
  position: relative;
  float: right;
  border: none;
  background: 0 0;
  padding: 0;
  @media only screen and (max-width: 425px) {
    width: 33px;
    height: 28px;
  }
}
.sidebar-link {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  display: block;
  &:hover,
  &:active {
    color: #e84c26;
  }
}
</style>
